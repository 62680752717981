import React from 'react'
import styled, { css } from 'styled-components'
import { colors } from 'styles/theme'

import useSlider from 'hooks/useSlider'
import scrollToSection from 'utils/scrollToSection'

import { Heading, Text } from 'components/shared/Typography'
import Container from 'components/shared/Container'
import Button from 'components/shared/Button'
import ArrowButton from 'components/shared/Button/Arrow'
import LazyImage from 'components/shared/LazyImage'

import Image from 'types/image'

export type HeaderSlide = {
  image: Image
  title: string
}

type Props = {
  slides: HeaderSlide[]
  scrollText: string
  scrollElement: string
  fixedTitle?: boolean
  withControlsSpace?: boolean
}

const Wrapper = styled.header`
  position: relative;
`

const InnerWrapper = styled.div`
  position: relative;
  min-height: 480px;
  max-height: 600px;
  height: ${({ theme }) => `calc(100vh - ${theme.navigation.mobile} - 50px)`};
  ${({ theme }) => theme.media.lg.min} {
    min-height: 580px;
    max-height: 900px;
    height: ${({ theme }) =>
      `calc(100vh - ${theme.navigation.desktop} - 50px)`};
  }
`

const ImageWrapper = styled.div<{ active: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  user-select: none;
  opacity: 0;
  visibility: hidden;
  transition: 0.6s;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: rgba(0, 0, 0, 0.4);
  }

  ${({ active }) =>
    active &&
    css`
      visibility: visible;
      opacity: 1;
    `}
`

const ContentWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 140px 0;
  z-index: 1;
  pointer-events: none;
  & > ${Container} {
    display: flex;
    align-items: center;
  }
`

const Content = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 750px;

  margin: 0 auto;

  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
  transform: translate3d(0, 50px, 0);
  pointer-events: none;

  ${({ theme }) => theme.media.md.min} {
    & > ${Button} {
      height: 60px;
    }
  }

  ${({ active }) =>
    active &&
    css`
      visibility: visible;
      opacity: 1;
      pointer-events: all;
      transform: translate3d(0, 0, 0);
      transition-delay: 0.8s;
    `}
`

const ControlArrows = styled.div<{ withSpace?: boolean }>`
  position: absolute;
  top: 0;
  bottom: 40px;
  left: 0;
  right: 0;
  z-index: 3;
  pointer-events: none;
  display: flex;
  align-items: flex-end;
  ${({ theme }) => theme.media.lg.min} {
    bottom: ${({ withSpace }) => (withSpace ? '90px' : '60px')};
  }
`

const ContainerContainer = styled(Container).attrs({ slim: true })`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const ScrollArrow = styled.button`
  display: flex;
  align-items: center;
  background: transparent;
  border: none;

  cursor: pointer;
  pointer-events: all;
`

const ScrollText = styled(Text)`
  margin-left: 0.6rem;
`

const FixedTitle = styled.div`
  pointer-events: all;
  & > ${Heading} {
    &:before,
    &:after {
      content: '';
      display: block;
      width: 50px;
      height: 1px;
      background: ${({ theme }) => theme.colors.primary};
      margin: 1rem 0;
      ${({ theme }) => theme.media.md.max} {
        margin: 2rem auto;
        width: 25px;
      }
    }
  }
  ${({ theme }) => theme.media.md.max} {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
`

const Header: React.FC<Props> = ({
  slides,
  scrollText,
  scrollElement,
  fixedTitle,
  withControlsSpace,
}) => {
  const { activeSlide, goNext, goPrev } = useSlider(slides)

  const goDown = () => {
    scrollToSection(scrollElement)
  }

  return (
    <Wrapper>
      <InnerWrapper>
        {slides.map(({ image, title }, index) => (
          <React.Fragment key={title + index}>
            <ImageWrapper active={index === activeSlide}>
              <LazyImage src={image.src} alt={image.alt ?? ''} fill />
            </ImageWrapper>
            <ContentWrapper>
              <Container slim fullHeight>
                {fixedTitle && index === 0 ? (
                  <FixedTitle>
                    <Heading
                      as="h1"
                      themecolor="white"
                      line="1.3"
                      weight={700}
                      dangerouslySetInnerHTML={{
                        __html: title,
                      }}
                    />
                  </FixedTitle>
                ) : (
                  <Content active={index === activeSlide}>
                    <Heading
                      as="h1"
                      align="center"
                      themecolor="white"
                      margin="0.5rem"
                      line="1.3"
                      weight={700}
                      dangerouslySetInnerHTML={{
                        __html: title,
                      }}
                    />
                  </Content>
                )}
              </Container>
            </ContentWrapper>
          </React.Fragment>
        ))}
        <ControlArrows withSpace={withControlsSpace}>
          <ContainerContainer slim>
            <ScrollArrow onClick={goDown}>
              {scrollText && scrollElement && (
                <>
                  <ArrowButton
                    tag="div"
                    direction="bottom"
                    background={colors.primary}
                    backgroundHover={colors.primary}
                  />
                  <ScrollText
                    transform="uppercase"
                    themecolor="white"
                    margin={0}
                    size={15}
                  >
                    {scrollText}
                  </ScrollText>
                </>
              )}
            </ScrollArrow>
            {slides.length > 1 && (
              <div>
                <ArrowButton onClick={goPrev} direction="left" />
                <ArrowButton onClick={goNext} direction="right" />
              </div>
            )}
          </ContainerContainer>
        </ControlArrows>
      </InnerWrapper>
    </Wrapper>
  )
}

export default Header
