import React, { useMemo } from 'react'
import { PageProps, graphql } from 'gatsby'

import Layout from 'components/layout/Layout'
import SEO from 'components/layout/SEO'
import Breadcrumbs from 'components/layout/Breadcrumbs'

import Header, { HeaderSlide } from 'components/layout/Headers/WithSlides'
import HeroSection from 'components/layout/Sections/AboutUs/Hero'
import MeetUsAbout from 'components/layout/Sections/AboutUs/About'
import Experience, {
  StepData,
} from 'components/layout/Sections/AboutUs/Experience'
import Mission, {
  MissionSlide,
} from 'components/layout/Sections/AboutUs/Mission'

import type { MeetUsPageQuery } from 'types/graphql'

const IndexPage: React.FC<PageProps<MeetUsPageQuery>> = ({ data }) => {
  const PAGE = data?.page?.pageAboutUs
  const PAGE_SEO = data?.page?.seo

  if (!PAGE || !PAGE_SEO) throw new Error("CMS data didn't load properly")

  const HEADER_SLIDES: HeaderSlide[] = useMemo(
    () =>
      PAGE.aboutUsPageS1Slider?.map((slide) => ({
        image: {
          src: slide?.aboutUsPageS1SliderElementImg?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: slide?.aboutUsPageS1SliderElementImg?.altText!,
        },
        title: slide?.aboutUsPageS1SliderElementTitle!,
      })) || [],
    [PAGE.aboutUsPageS1Slider]
  )

  const EXPERIENCE_STEPS: StepData[] = useMemo(
    () =>
      PAGE.aboutUsPageS5List?.map((item) => ({
        image: {
          src: item?.aboutUsPageS5ListElementImg?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: item?.aboutUsPageS5ListElementImg?.altText!,
        },
        title: item?.aboutUsPageS5ListElementTitle!,
        text: item?.aboutUsPageS5ListElementDescription!,
      })) || [],
    [PAGE.aboutUsPageS5List]
  )

  const MISSION_SLIDES: MissionSlide[] = useMemo(
    () =>
      PAGE.aboutUsPageS4Slider?.map((slide) => ({
        image: {
          src: slide?.aboutUsPageS4SliderElementImg?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: slide?.aboutUsPageS4SliderElementImg?.altText!,
        },
        title: slide?.aboutUsPageS4SliderElementTitle!,
        text: slide?.aboutUsPageS4SliderElementDescription!,
      })) || [],
    [PAGE.aboutUsPageS4Slider]
  )

  return (
    <Layout>
      <SEO seo={PAGE_SEO} />
      <Breadcrumbs crumbs={[{ name: 'O nas' }]} />
      <Header
        slides={HEADER_SLIDES}
        scrollText="Zjedź niżej"
        scrollElement="#main"
        withControlsSpace
      />
      <main id="main">
        {PAGE.aboutUsPageS2List && PAGE.aboutUsPageS2List?.length >= 4 && (
          <MeetUsAbout
            title={PAGE!.aboutUsPageS2Title!}
            text={PAGE!.aboutUsPageS2Description!}
            firstDataColumn={{
              text: PAGE.aboutUsPageS2List[0]
                ?.aboutUsPageS2ListElementDescription!,
              title: PAGE.aboutUsPageS2List[0]?.aboutUsPageS2ListElementTitle!,
              themecolor: 'primary',
              image: {
                src: PAGE.aboutUsPageS2List[1]?.aboutUsPageS2ListElementImg!
                  .localFile?.childImageSharp?.gatsbyImageData!,
                alt: PAGE.aboutUsPageS2List[1]?.aboutUsPageS2ListElementImg!
                  .altText!,
              },
            }}
            secondDataColumn={{
              text: PAGE.aboutUsPageS2List[3]
                ?.aboutUsPageS2ListElementDescription!,
              title: PAGE.aboutUsPageS2List[3]?.aboutUsPageS2ListElementTitle!,
              themecolor: 'dark',
              image: {
                src: PAGE.aboutUsPageS2List[2]?.aboutUsPageS2ListElementImg!
                  .localFile?.childImageSharp?.gatsbyImageData!,
                alt: PAGE.aboutUsPageS2List[2]?.aboutUsPageS2ListElementImg!
                  .altText!,
              },
            }}
          />
        )}

        <HeroSection
          title={PAGE.aboutUsPageS3Title!}
          background={{
            src: PAGE.aboutUsPageS3Img?.localFile?.childImageSharp
              ?.gatsbyImageData!,
            alt: PAGE.aboutUsPageS3Img?.altText!,
          }}
        />

        <Mission slides={MISSION_SLIDES} />

        <Experience
          title={PAGE.aboutUsPageS5Title!}
          text={PAGE.aboutUsPageS5Description!}
          steps={EXPERIENCE_STEPS}
        />
      </main>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query MeetUsPage($locale: String) {
    page: wpPage(
      language: { slug: { eq: $locale } }
      slug: { regex: "/o-nas|about-us/" }
    ) {
      seo {
        ...WpSEO
      }
      pageAboutUs {
        aboutUsPageS1Slider {
          aboutUsPageS1SliderElementTitle
          aboutUsPageS1SliderElementImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 2000
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
        aboutUsPageS2Description
        aboutUsPageS2List {
          aboutUsPageS2ListElementDescription
          aboutUsPageS2ListElementTitle
          aboutUsPageS2ListElementType
          aboutUsPageS2ListElementImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 1280
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
        aboutUsPageS2Title
        aboutUsPageS3Img {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 2000
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        aboutUsPageS3Title
        aboutUsPageS4Slider {
          aboutUsPageS4SliderElementDescription
          aboutUsPageS4SliderElementImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 2000
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
          aboutUsPageS4SliderElementTitle
        }
        aboutUsPageS5Description
        aboutUsPageS5List {
          aboutUsPageS5ListElementDescription
          aboutUsPageS5ListElementTitle
          aboutUsPageS5ListElementImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 960
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
        aboutUsPageS5Title
      }
    }
  }
`
