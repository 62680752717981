import React from 'react'
import styled, { css } from 'styled-components'

import Container from 'components/shared/Container'
import LazyImage from 'components/shared/LazyImage'
import { Heading } from 'components/shared/Typography'

import type Image from 'types/image'

type Props = {
  background: Image
  title?: string
  headingLines?: boolean
  titleShiftBottom?: boolean
}

const Section = styled.section`
  position: relative;
  padding: 3rem 0;
  height: 100vh;
  max-height: 600px;
  min-height: min-content;

  display: flex;
  align-items: center;
  ${({ theme }) => theme.media.md.min} {
    max-height: 1000px;
  }
`

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const HeadingWrapper = styled.div<{ shiftBottom?: boolean }>`
  position: relative;
  z-index: 1;
  max-width: 650px;
  & > ${Heading} {
    margin: 3rem 0;
  }

  ${({ shiftBottom }) =>
    shiftBottom &&
    css`
      transform: translateY(120px);

      ${({ theme }) => theme.media.lg.min} {
        transform: translateY(150px);
      }

      ${({ theme }) => theme.media.xl.min} {
        transform: translateY(220px);
      }

      ${({ theme }) => theme.media.xxl.min} {
        transform: translateY(240px);
      }

      ${({ theme }) => theme.media.lg.min} {
        @media (max-height: 900px) {
          transform: translateY(180px);

          span {
            font-size: 5rem;
          }
        }

        @media (max-height: 700px) {
          transform: translateY(160px);

          span {
            font-size: 4.5rem;
          }
        }
      }
    `}
`

const Line = styled.span`
  display: block;
  width: 50px;
  height: 1px;
  background: ${({ theme }) => theme.colors.primary};
  margin: 0 0.5rem;
`

const HeroSection: React.FC<Props> = ({
  title,
  background,
  headingLines,
  titleShiftBottom,
}) => {
  return (
    <Section>
      <Background>
        <LazyImage
          src={background.src}
          alt={background.alt! ?? title!}
          fill
          objectPosition="60% 50%"
        />
      </Background>
      <Container slim>
        <HeadingWrapper shiftBottom={titleShiftBottom}>
          {headingLines && <Line />}
          <Heading
            as="span"
            size={90}
            weight={500}
            sizeDiff={0.9}
            themecolor="white"
            line="1"
            dangerouslySetInnerHTML={{ __html: title! }}
          />
          {headingLines && <Line />}
        </HeadingWrapper>
      </Container>
    </Section>
  )
}

export default HeroSection
