import React from 'react'
import styled, { css } from 'styled-components'

import useSlider from 'hooks/useSlider'

import { Content, Heading } from 'components/shared/Typography'
import Container from 'components/shared/Container'
import { Row, Col } from 'components/shared/Grid'
import ArrowButton from 'components/shared/Button/Arrow'

import type Image from 'types/image'
import LazyImage from 'components/shared/LazyImage'

export type MissionSlide = {
  title: string
  text: string
  image: Image
}

type Props = {
  slides: MissionSlide[]
}

const Section = styled.section`
  position: relative;
  padding: 8rem 0 7rem;
  margin-bottom: 5vw;
  ${({ theme }) => theme.media.lg.max} {
    padding: 6rem 0 5rem;
  }
  ${({ theme }) => theme.media.md.max} {
    padding: 4rem 0 2rem;
    margin-bottom: 0;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 40%;
    bottom: -5vw;
    background: ${({ theme }) => theme.colors.lightgray};
    ${({ theme }) => theme.media.md.max} {
      top: 0;
      right: 0;
      left: 0;
      bottom: 30%;
    }
  }
`

const SlideContent = styled(Col)`
  position: relative;
  z-index: 2;

  min-height: 450px;
  ${({ theme }) => theme.media.md.max} {
    min-height: 350px;
  }
  ${({ theme }) => theme.media.sm.max} {
    min-height: 500px;
  }
`

const SlideImageWrapper = styled(Col)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  ${({ theme }) => theme.media.md.max} {
    position: relative;
    height: 70vw;
  }
  ${({ theme }) => theme.media.sm.max} {
    height: 120vw;
  }
`

const SlideImage = styled.div<{ active: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  opacity: 0;

  transition: opacity 200ms ease;

  /* transition-property: opacity, visibility;
  transition-duration: 200ms;
  transition-timing-function: ease; */

  ${({ active }) =>
    active &&
    css`
      opacity: 1;
    `}
`

const Controls = styled.div`
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;

  display: flex;
  flex-direction: column;

  padding: 2rem 1rem;
  background: ${({ theme }) => theme.colors.primary};

  ${({ theme }) => theme.media.md.max} {
    position: static;
    width: 100%;
    flex-direction: row;

    padding: 1.5rem 1rem;
  }
`

const MeetUsMission: React.FC<Props> = ({ slides }) => {
  const { activeSlide, goNext, goPrev } = useSlider(slides, 0, false)

  return (
    <Section>
      <Container>
        <Row alignItems="center">
          <SlideContent
            xs={24}
            offset-xxl={15}
            xxl={9}
            offset-lg={14}
            lg={10}
            offset-md={13}
            md={11}
          >
            <Heading
              as="h2"
              dangerouslySetInnerHTML={{ __html: slides[activeSlide].title }}
              size={50}
              weight={600}
            />
            <Content
              dangerouslySetInnerHTML={{ __html: slides[activeSlide].text }}
            />
          </SlideContent>
        </Row>
      </Container>
      <SlideImageWrapper xxl={14} lg={13} md={12} xs={24}>
        {slides.map((slide, index) => (
          <SlideImage active={index === activeSlide}>
            <LazyImage src={slide.image.src} alt={slide.image.alt || ''} fill />
          </SlideImage>
        ))}
      </SlideImageWrapper>
      <Controls>
        <ArrowButton direction="left" onClick={goPrev} />
        <ArrowButton direction="right" onClick={goNext} />
      </Controls>
    </Section>
  )
}

export default MeetUsMission
