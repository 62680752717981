import React from 'react'
import styled from 'styled-components'

import { Content, Heading } from 'components/shared/Typography'
import Container from 'components/shared/Container'
import { Row, Col } from 'components/shared/Grid'

import Step from 'components/layout/Sections/AboutUs/Experience/Step'

import type Image from 'types/image'

export type StepData = {
  title: string
  text: string
  image: Image
}

type Props = {
  title: string
  text: string
  steps: StepData[]
}

const Section = styled.section`
  padding: 3rem 0 4rem;
  ${({ theme }) => theme.media.lg.max} {
    padding: 3rem 0;
  }
  ${({ theme }) => theme.media.sm.max} {
    padding: 2rem 0;
  }
`

const StepsWrapper = styled.div`
  ${({ theme }) => theme.media.md.min} {
    max-height: 600px;
    overflow-x: hidden;
    overflow-y: auto;
  }
`

const MeetUsAbout: React.FC<Props> = ({ title, text, steps }) => {
  return (
    <Section>
      <Container>
        <Row alignItems="center">
          <Col xs={24} md={11}>
            <Heading
              as="h2"
              dangerouslySetInnerHTML={{ __html: title }}
              size={50}
              weight={600}
            />
            <Content dangerouslySetInnerHTML={{ __html: text }} />
          </Col>
          <Col xs={24} offset-md={1} md={12} padding="0px">
            <StepsWrapper>
              {steps.map((year, index) => (
                <Step
                  isEven={index % 2 === 0}
                  title={year.title}
                  text={year.text}
                  image={year.image}
                />
              ))}
            </StepsWrapper>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

export default MeetUsAbout
