import React from 'react'
import styled from 'styled-components'

import { Content, Heading, Text } from 'components/shared/Typography'
import { Row, Col } from 'components/shared/Grid'
import LazyImage from 'components/shared/LazyImage'
import Container from 'components/shared/Container'

import type Image from 'types/image'
import { Colors } from 'types/theme'

type DataCol = {
  themecolor: keyof Colors
  title: string
  text: string
  image: Image
}

type Props = {
  title: string
  text: string
  firstDataColumn: DataCol
  secondDataColumn: DataCol
}

const Section = styled.section`
  position: relative;
  min-height: 500px;
`

const SectionContaner = styled(Container)`
  min-height: inherit;
`

const TextColumn = styled(Col)`
  padding: 3rem 0 4rem;

  ${({ theme }) => theme.media.lg.max} {
    padding: 3rem 0;
  }
`

const DataColumns = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 2;

  display: flex;
  justify-content: flex-end;

  ${({ theme }) => theme.media.lg.max} {
    position: static;
    height: auto;
  }
  ${({ theme }) => theme.media.sm.max} {
    display: block;
  }
`

const FirstDataColumn = styled(Col)`
  position: relative;
  bottom: 0;
  top: -10%;
  height: 110%;

  ${({ theme }) => theme.media.lg.max} {
    height: auto;
    position: static;
  }
`

const SecondDataColumn = styled(Col)`
  position: relative;
  bottom: -10%;
  top: 0;
  height: 110%;

  ${({ theme }) => theme.media.lg.max} {
    height: auto;
    position: static;
    display: flex;
    flex-direction: column;
  }

  ${({ theme }) => theme.media.sm.max} {
    flex-direction: column-reverse;
  }
`

const DataColumnImage = styled(LazyImage)`
  height: 50%;
  ${({ theme }) => theme.media.lg.max} {
    height: 50vw;
  }
  ${({ theme }) => theme.media.sm.max} {
    height: 100vw;
  }
`

const DataColumnContent = styled.div<{ themecolor?: keyof Colors }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem 2.5rem;
  height: 50%;
  background: ${({ theme, themecolor }) =>
    themecolor ? theme.colors[themecolor] : theme.colors.primary};

  ${({ theme }) => theme.breakpoints.md} {
    padding: 1.5rem;
  }
  ${({ theme }) => theme.media.lg.max} {
    height: 50vw;
  }
  ${({ theme }) => theme.media.sm.max} {
    height: 100vw;
  }
`

const MeetUsAbout: React.FC<Props> = ({
  title,
  text,
  firstDataColumn,
  secondDataColumn,
}) => {
  return (
    <Section>
      <SectionContaner>
        <Row alignItems="center" fullHeight>
          <TextColumn xs={24} lg={11}>
            <Heading
              as="h2"
              dangerouslySetInnerHTML={{ __html: title }}
              size={50}
              weight={600}
            />
            <Content dangerouslySetInnerHTML={{ __html: text }} />
          </TextColumn>
        </Row>
      </SectionContaner>
      <DataColumns>
        <FirstDataColumn xs={24} sm={12} lg={6} padding="0px">
          <DataColumnContent themecolor={firstDataColumn.themecolor}>
            <Text
              themecolor="white"
              size={80}
              dangerouslySetInnerHTML={{ __html: firstDataColumn.title }}
            />
            <Text
              themecolor="white"
              size={22}
              dangerouslySetInnerHTML={{ __html: firstDataColumn.text }}
            />
          </DataColumnContent>
          <DataColumnImage
            src={firstDataColumn.image.src}
            alt={firstDataColumn.image.alt ?? ''}
            fill
          />
        </FirstDataColumn>
        <SecondDataColumn xs={24} sm={12} lg={6} padding="0px">
          <DataColumnImage
            src={secondDataColumn.image.src}
            alt={secondDataColumn.image.alt ?? ''}
            fill
          />
          <DataColumnContent themecolor={secondDataColumn.themecolor}>
            <Text
              themecolor="white"
              size={80}
              dangerouslySetInnerHTML={{ __html: secondDataColumn.title }}
            />
            <Text
              themecolor="white"
              size={22}
              dangerouslySetInnerHTML={{ __html: secondDataColumn.text }}
            />
          </DataColumnContent>
        </SecondDataColumn>
      </DataColumns>
    </Section>
  )
}

export default MeetUsAbout
