import React from 'react'
import styled from 'styled-components'

import { Row, Col } from 'components/shared/Grid'
import { Text } from 'components/shared/Typography'

import LazyImage from 'components/shared/LazyImage'

import type Image from 'types/image'

type Props = {
  isEven: boolean
  title: string
  text: string
  image: Image
}

const Wrapper = styled.div`
  margin: 1rem 0;
  min-height: 225px;
`

const StepContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem 0;
`

const StepText = styled(Text)`
  border-left: ${({ theme }) => theme.colors.primary} 3px solid;
  padding-left: 0.5rem;
  padding-right: 0.75rem;
`

const StyledImageWrapper = styled.div`
  height: 225px;

  ${({ theme }) => theme.media.sm.max} {
    height: 250px;
    margin-bottom: -1rem;
  }
`

const Step: React.FC<Props> = ({ title, text, image, isEven = false }) => {
  return (
    <Wrapper>
      <Row reverse={!isEven} alignItems="stretch" fullHeight>
        <Col xs={24} sm={12}>
          <StyledImageWrapper>
            <LazyImage src={image.src} alt={image.alt ?? ''} fill />
          </StyledImageWrapper>
        </Col>
        <Col xs={0} sm={1} />
        <Col xs={24} sm={11}>
          <StepContent>
            <Text
              themecolor="primary"
              size={30}
              weight={600}
              margin="0.5rem"
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <StepText dangerouslySetInnerHTML={{ __html: text }} />
          </StepContent>
        </Col>
      </Row>
    </Wrapper>
  )
}

export default Step
